<template>


<el-table :data="info.results" style="width: 100%">
  <el-table-column label="日期" prop="url" />
  <el-table-column label="姓名e" prop="id" />
  <el-table-column label="标题" prop="title" />





  <el-table-column align="right"> 
     <template #header>
       <el-input v-model="search" size="small" placeholder="Type to search" /> 
     </template> 
     <template #default="scope"> 

       <el-button size="small" @click="handleEdit(scope.$index, scope.row)" >
          <a :href="id">编辑</a>
       </el-button > 
       <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)" >
          删除
       </el-button >
     </template> 
  </el-table-column>
</el-table> 
</template>


<script>


  import {  ref,computed,toRaw } from 'vue'

  import {useRoute} from 'vue-router'
  import getArticleData from '@/composables/getArticleData.js'
  import pagination from '@/composables/pagination.js'
  import articleGrid from '@/composables/articleGrid.js'
  import formattedTime from '@/composables/formattedTime.js'
  export default {
    name:'TaBle',
    setup(){
      const search = ref('')
      const info = ref('');
      const id=ref();





      const route = useRoute();

      const kwargs = ref({page: 0, searchText: ''});
      getArticleData(info, route, kwargs);
      const handleEdit = (index, row) =>{
        //console.log(index,row);
        id.value='/article/'+row.id;
        console.log('gd',id.value)



      }
      const handleDelete = (index, row) => {
        console.log(index, row);




      }




      const {
                is_page_exists,
                get_page_param,
                get_path
      } = pagination(info, route);

      const {
                imageIfExists,
                gridStyle
      } = articleGrid();

      const formatted_time = formattedTime;
      return{
        handleEdit,
        handleDelete,


        search,
        id,


        info,

        is_page_exists,
        get_page_param,
        get_path,
        imageIfExists,
        gridStyle,
        formatted_time,

      }

    },




  };





</script>