<template>
    <div id="aside">

      <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
    <el-radio-button :label="false">展开</el-radio-button>
    <el-radio-button :label="true">收缩</el-radio-button>
  </el-radio-group>
  <el-menu
    default-active="2"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
  >
    <el-sub-menu v-if=0 index="1" width=10%>
      <template #title>
        <el-icon><location /></el-icon>
        <span>功能 One</span>
      </template>
      <el-menu-item-group>
        <template #title><span>分组 One</span></template>
        <el-menu-item index="1-1">项目 one</el-menu-item>
        <el-menu-item index="1-2">项目 two</el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group title="分组 Two">
        <el-menu-item index="1-3">项目 three</el-menu-item>
      </el-menu-item-group>
      <el-sub-menu index="1-4">
        <template #title><span>项目 four</span></template>
        <el-menu-item index="1-4-1">子项目 one</el-menu-item>
      </el-sub-menu>
    </el-sub-menu>
    <el-menu-item v-if=1 index="2">
      <el-icon><icon-menu /></el-icon>
      <template #title><a href="/">主页</a></template>
    </el-menu-item>
    <el-menu-item index="3">
      <el-icon><document /></el-icon>
      <template #title>
        报表功能
        <a href="/table" style="color:white" color="white">
           打开报表
        </a>
      </template>
    </el-menu-item>
    <el-menu-item index="4">
      <el-icon><setting /></el-icon>
      <template #title>设置功能 Four</template>
    </el-menu-item>
  </el-menu>


    </div>
</template>

<script>
    import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue';

    export default {
        name: 'Aside',
        components: {Document,IconMenu,Location,Setting,},
        data: function () {
            return {
              isCollapse:true

            }
        },
        mounted() {
        },
        methods: {
          handleOpen(key,keyPath){
            console.log(key, keyPath);

          },
          handleClose(key,keyPath){
            console.log(key, keyPath);

          },


        }
    }
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width:10%;


  min-height:;

}
</style>