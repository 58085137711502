<template>
  <div class="common-layout">
    <el-container>
      <el-header class="bt"><BlogHeader/></el-header>

      <el-container class="nr">

        <el-aside width=25% ><Aside/></el-aside>
        <el-main><TaBle/></el-main>

      </el-container>

      <el-footer><BlogFooter/></el-footer>
    </el-container>
  </div>

</template>

<script>
    import BlogHeader from '@/components/BlogHeader.vue'
    import BlogFooter from '@/components/BlogFooter.vue'

    import Aside from '@/components/Aside.vue'
    import TaBle from '@/components/TaBle.vue'

    export default {
        name: 'TableView',
        components: {BlogHeader, BlogFooter,Aside,TaBle},
    };
</script>
<style>
.nr{
  margin-top: 15%;
}
.bt{
  width: 100%;
}

</style>
