<template>
  <div class="common-layout">
    <el-container>
      <el-header class="bt"><BlogHeader/></el-header>
      <el-container class="nr">
        <el-aside width=25% ><Aside/></el-aside>
        
          <el-main><ArticleList/></el-main>
          

      </el-container>

      <el-footer><BlogFooter/></el-footer>
    </el-container>
  </div>

</template>

<script>
    import BlogHeader from '@/components/BlogHeader.vue'
    import BlogFooter from '@/components/BlogFooter.vue'
    import ArticleList from '@/components/ArticleList.vue'
    import Aside from '@/components/Aside.vue'


    export default {
        name: 'Home',
        components: {BlogHeader, BlogFooter, ArticleList,Aside}
    }
</script>
<style>


</style>
