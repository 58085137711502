<template>
    <br>
    <br>
    <br>
    <div id="footer">

          <p>
            www.huazai888.cn
            <br/>


            Powered by<a href="https://beian.miit.gov.cn" style="color: lightskyblue;" target="_blank">&nbsp;闽ICP备2023004699号</a>
          </p>


    </div>
</template>

<script>
    export default {
        name: 'BlogFooter'
    }
</script>

<style scoped>
    #footer {
        position: fixed;
        left: 0;
        bottom: 0;

        height: 50px;
        width: 100%;
        padding-bottom: 10px;
        padding-top: 0;
        background: whitesmoke;
        text-align: center;
        font-weight: bold;
        color: lightgrey;
    }
</style>